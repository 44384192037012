<template>
  <div class="alertContainer">
    <div>
      <transition-group name="fade">
        <div v-for="(error, index) in getErrors" :key="error.id">
          <div class="alertBox">
            <div class="exitCross" @click="removeError(index)">
              <b-icon-x-circle class="h2" color="gray"></b-icon-x-circle>
            </div>
            <h3>{{ error.title }}</h3>
            <div>{{ error.message }}</div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["getErrors"]),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["removeError"]),
  },
};
</script>

<style scoped>
.alertBox {
  border-radius: 25px;
  border: solid red 3px;
  background-color: rgba(247, 55, 55, 0.8);
  width: 20vw;
  min-height: 10vh;
  margin: 10px;
  padding: 10px;
  color: black;
}
.alertContainer {
  position: fixed;
  right: 40px;
  top: 80px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.exitCross {
  /* position: absolute;
  right: 25px;
  top: 25px; */
  float: right;
}
.exitCross :hover {
  color: black;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>